<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form @submit.prevent="handleSubmit(onSubmit)" v-model="valid">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="85vh">
                    <v-container style="height: 85vh">
                        <div class="d-flex align-center mt-16 mb-15">
                            <v-icon class="is-icon px-2" color="primary" large>
                                {{ mdiInformationOutline }}
                            </v-icon>
                            <p
                                class="text-h6 font-weight-light black--text ml-3 mb-0 d-none d-md-flex d-lg-flex d-xl-flex">
                                {{ $t('OrganizationData') }}
                            </p>
                            <p
                                class="body-1 font-weight-light black--text ml-3 mb-0 d-flex d-xs-flex d-sm-flex d-md-none">
                                {{ $t('OrganizationData') }}
                            </p>
                        </div>
                        <v-row class="d-flex flex-row-reverse">
                            <v-col class="form-pic d-flex justify-center" cols="12" md="3">
                                <v-avatar
                                    :color="form.picture ? '' : 'primary'"
                                    :class="form.picture ? '' : 'v-avatar-light-bg primary--text'"
                                    size="200">
                                    <figure>
                                        <v-img max-width="190" v-if="getPic" :src="getPic"></v-img>
                                        <span v-else class="display-1 font-weight-medium">{{
                                            avatarText(form.name)
                                        }}</span>
                                    </figure>

                                    <v-file-input
                                        max-width="190"
                                        @change="onFileInput"
                                        name="photo"
                                        v-show="!isDisabled"
                                        v-model="file"
                                        class="file"
                                        hide-input></v-file-input>
                                </v-avatar>
                            </v-col>
                            <v-col class="d-flex flex-column mr-auto" cols="12" md="8">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <ValidationProvider
                                            :name="$t('Form.Name')"
                                            rules="required"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                v-model="form.name"
                                                :label="$t('Form.Name')"
                                                required
                                                filled
                                                hide-details="auto"
                                                shaped></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <ValidationProvider :name="$t('Form.Nif')" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                name="NIF"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                v-model="form.NIF"
                                                :label="$t('Form.Nif')"
                                                required></v-text-field
                                        ></ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <ValidationProvider
                                            :name="$t('Form.Email')"
                                            rules="required|email"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                v-model="form.email"
                                                :label="$t('Form.Email')"
                                                hide-details="auto"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <ValidationProvider
                                            :name="$t('Form.Phone')"
                                            rules="mobile-phone"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                v-model="form.phone"
                                                :label="$t('Form.Phone')"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-container class="d-flex">
                        <v-toolbar-title class="d-flex justify-center">
                            <p
                                class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                                {{ $t('Organization') }}
                            </p>
                            <p
                                class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                                {{ $t('Organization') }}
                            </p>
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <div>
                            <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                                <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                    {{ mdiCheck }}
                                </v-icon>
                                <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                                    {{ mdiCheck }}
                                </v-icon>
                                <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                            </v-btn>
                            <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                                <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                    {{ mdiClose }}
                                </v-icon>
                                <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                    {{ mdiClose }}
                                </v-icon>
                                <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                            </v-btn>
                            <v-btn @click="editInformation" v-show="isDisabled" class="mx-3" color="grey-light">
                                <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                    {{ mdiFileDocumentEditOutline }}
                                </v-icon>
                                <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                    {{ mdiFileDocumentEditOutline }}
                                </v-icon>
                                <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                            </v-btn>
                        </div>
                        <!-- ALERTAS DE SUCESSO E ERRO A ATUALIZAR ORGANIZAÇÃO-->
                        <v-snackbar :timeout="2000" top v-model="isSuccess" color="white">
                            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
                            <p class="text-center title font-weight-semibold black--text mt-5">
                                {{ $t('Alerts.UpdateSuccess') }}
                            </p>
                        </v-snackbar>
                        <v-snackbar :timeout="2000" top v-model="isError" color="white">
                            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
                            <p class="text-center title font-weight-semibold black--text mt-5">
                                {{ $t('Alerts.Error') }}
                            </p>
                            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
                        </v-snackbar>
                    </v-container>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
    </section>
</template>
<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import {
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
    } from '@mdi/js';
    import { avatarText } from '@core/utils/filter';
    import { uploadFile } from '@/api/upload';
    import store from '@/store';

    const initialState = () => ({
        form: {
            name: '',
            NIF: '',
            email: '',
            phone: '',
            pictures: null,
            id: null,
        },
        file: null,
        isLoading: false,
        isSuccess: false,
        isError: false,
    });

    const resolveParkPictureMax = (pictures) => {
        if (_.get(pictures, '[0].formats.large.url')) return _.get(pictures, '[0].formats.large.url');

        if (_.get(pictures, '[0].url')) return _.get(pictures, '[0].url');
        return null;
    };
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,
            };
        },
        computed: {
            getPic() {
                return this.resolveParkPictureMax(this.form.pictures);
            },
        },
        setup() {
            const userRole = store.getters['auth/getUser'].role;
            const bgColor = userRole.name === 'ioBus' || 'ioParking' ? 'bg-primary-red' : 'bg-primary';
            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiTrashCanOutline,
                mdiPlus,
                mdiCheck,
                mdiClose,
                avatarText,
                resolveParkPictureMax,
                bgColor,
            };
        },
        created() {
            this.getOrganization();
        },
        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },
            getOrganization() {
                this.$store
                    .dispatch('organization/fetchOrganization', this.$store.getters['auth/getUser'].entity.id)
                    .then((response) => {
                        this.form.name = response.data.data.name;
                        this.form.NIF = response.data.data.NIF;
                        this.form.email = response.data.data.email;
                        this.form.phone = response.data.data.phone;
                        this.form.id = response.data.data.id;
                        this.form.pictures = response.data.data.pictures;
                    })
                    .catch((error) => {
                        this.form = initialState().form;
                    });
            },

            onSubmit() {
                this.isLoading = true;
                this.$store
                    .dispatch('organization/updateOrganization', this.form)
                    .then(() => {
                        this.isSuccess = true;
                        this.isLoading = false;
                        this.getOrganization();
                        this.isDisabled = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },
            async onFileInput() {
                this.isLoading = true;
                await uploadFile(this.file).then((response) => {
                    this.form.pictures = [response.data[0]];
                });
                this.isLoading = false;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .is-icon {
        border-radius: 10px;
    }
</style>
